import React from 'react';
import '../styles/Block3.css';

const containerStyle = {
    display: 'flex',
    width: '100%',
    maxWidth: 744,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap' as const,
    gap: '20px 24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: '1',
};

const stepContainerStyle = {
    justifyContent: 'flex-start',
    width: '360px',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex' as const,
};

const numberStyle = {
    width: 56,
    opacity: 0.20,
    textAlign: 'right' as const,
    color: 'white',
    fontSize: 64,
    fontFamily: 'Unbounded',
    fontWeight: 600,
    wordWrap: 'break-word' as const,
};

const textContainerStyle = {
    flex: '1 1 0' as const,
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex' as const,
};

const titleStyle = {
    alignSelf: 'stretch',
    color: 'white',
    fontSize: 24,
    fontFamily: 'Unbounded',
    fontWeight: 700,
    wordWrap: 'break-word' as const,
};

const linkStyle = {
    color: '#A154FE',
    fontSize: 20,
    fontFamily: 'Unbounded',
    fontWeight: 400,
    textDecoration: 'underline',
    wordWrap: 'break-word' as const,
};

const descriptionStyle = {
    color: '#C1C1C1',
    fontSize: 20,
    fontFamily: 'Unbounded',
    fontWeight: 400,
    wordWrap: 'break-word' as const,
};

const importantContainerStyle = {
    width: 360,
    padding: 16,
    background: '#A154FE',
    borderRadius: 16,
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex' as const,
    boxSizing: 'border-box' as const,
};

const importantTextStyle = {
    color: 'white',
    fontSize: 20,
    fontFamily: 'Unbounded',
    fontWeight: 700,
    wordWrap: 'break-word' as const,
};

const importantDescriptionStyle = {
    color: '#DDDDDD',
    fontSize: 20,
    fontFamily: 'Unbounded',
    fontWeight: 400,
    wordWrap: 'break-word' as const,
};

const Block3: React.FC = () => {

    const handleClick = () => {
        window.open('https://telegra.ph/KAK-KUPIT-TOKEN-ANGEL-09-13', '_blank');
    };

    return (
        <div style={containerStyle}>
            <div className="InstrButton" onClick={handleClick}>
                <div className="InstrButtonText">Подробная инструкция</div>
            </div>
            <div style={{ ...stepContainerStyle, height: 143 }}>
                <div style={numberStyle}>1</div>
                <div style={textContainerStyle}>
                    <div style={titleStyle}>Установите TronLink</div>
                    <div style={{ alignSelf: 'stretch' }}>
                        <a href="https://www.tronlink.org/" target="_blank" rel="noopener noreferrer" style={linkStyle}>Загрузите кошелек TronLink</a>
                        <span style={descriptionStyle}> (Android, iOS, в браузере)</span>
                    </div>
                </div>
            </div>
            <div style={{ ...stepContainerStyle, height: 113 }}>
                <div style={numberStyle}>2</div>
                <div style={textContainerStyle}>
                    <div style={titleStyle}>Пополните TRX</div>
                    <div style={descriptionStyle}>Купите монеты TRX на бирже и переведите их на TronLink.</div>
                </div>
            </div>
            <div style={{ ...stepContainerStyle, height: 173 }}>
                <div style={numberStyle}>3</div>
                <div style={textContainerStyle}>
                    <div style={titleStyle}>Подключите кошелек к SunPump</div>
                    <div style={descriptionStyle}>Перейдите на сайт SunPump и нажмите «Connect Wallet»</div>
                </div>
            </div>
            <div style={{ ...stepContainerStyle, height: 143 }}>
                <div style={numberStyle}>4</div>
                <div style={textContainerStyle}>
                    <div style={titleStyle}>Найдите токен $ANGEL</div>
                    <div style={descriptionStyle}>Введите название токена в поиске на SunPump.</div>
                </div>
            </div>
            <div style={{ ...stepContainerStyle, height: 113 }}>
                <div style={numberStyle}>5</div>
                <div style={textContainerStyle}>
                    <div style={titleStyle}>Купите токен</div>
                    <div style={descriptionStyle}>Укажите количество TRX, нажмите «Trade», и подтвердите покупку</div>
                </div>
            </div>
            <div style={importantContainerStyle}>
                <div style={{ alignSelf: 'stretch' }}>
                    <span style={importantTextStyle}>Важно:</span>
                    <span style={importantTextStyle}> </span>
                    <span style={importantDescriptionStyle}>для оплаты комиссии TronLink на балансе должно оставаться не менее 2$</span>
                </div>
            </div>
        </div>
    );
};

export default Block3;