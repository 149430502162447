import React from 'react';
import './../styles/Block2.css';
import { ReactComponent as YoutubeIcon } from './../assets/icons/youtube.svg';
import { ReactComponent as TelegramIcon } from './../assets/icons/telegram.svg';
import { ReactComponent as InstagramIcon } from './../assets/icons/instagram.svg';
import { ReactComponent as XIcon } from './../assets/icons/x.svg';

const Block2: React.FC = () => {
    return (
        <div className="block2">
            <img src="/images/image2.png" alt="Project Image" className="project-image" />
            <div className="main_info">
                <div className="text_part" style={{width: '343px'}}>
                    <h1 className="title">О НАС</h1>
                    <p className="description">Наша команда, состоящая из профессионалов индустрии блокчейн, создала токен народного комьюнити<br /><br />Наша цель – сделать криптовалюты доступными для всех, независимо от их опыта или местоположения</p>
                </div>
                <div className="social-buttons">
                    <a target="_blank" href="https://t.me/angel_token1" className="social-button"><TelegramIcon /></a>
                    <a target="_blank" href="https://www.instagram.com/angelina_crypto1?igsh=ejB0M21jNTJuaWxj&utm_source=qr" className="social-button"><InstagramIcon /></a>
                    <a target="_blank" href="https://x.com/AngelMemeToken?t=KCldovP99NW7F4WZzjToEQ&s=09" className="social-button"><XIcon /></a>
                </div>
            </div>
        </div>
    );
};

export default Block2;