import React from 'react';
import '../../styles/CTAButton.css';
import { ReactComponent as SunpumpIcon } from '../../assets/icons/sunpump.svg';

interface CTAButtonProps {
    onClick: () => void;
}

const CTAButton: React.FC<CTAButtonProps> = ({ onClick }) => {
    return (
        <button className="cta-button" onClick={onClick}>
            <SunpumpIcon className="cta-button-icon" />
            <span style={{whiteSpace: 'nowrap'}}>Купить на SunPump</span>
        </button>
    );
};

export default CTAButton;