import React from 'react';
import '../../styles/Button3.css';

interface Button3Props {
    text: string;
    href: string;
}

const Button3: React.FC<Button3Props> = ({ text, href }) => {
    return (
        <a href={href} target="_blank" className="button3">
            <p className="button3_text">{text}</p>
        </a>
    );
};

export default Button3;