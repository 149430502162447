import React from 'react';
import '../styles/Tape.css';

interface TapeProps {
    direction: 'left' | 'right';
}

const Tape: React.FC<TapeProps> = ({ direction }) => {
    const tapeClass = `tape ${direction}`;
    const text = "$ANGEL · ";

    return (
        <div className={tapeClass}>
            <div className="tape-content">
                {[...Array(20)].map((_, index) => (
                    <span key={index}>{text}</span>
                ))}
            </div>
        </div>
    );
};

export default Tape;