import React, {useState} from 'react';
import Button3 from './buttons/Button3';
import './../styles/Block1.css';
import { ReactComponent as YoutubeIcon } from './../assets/icons/youtube.svg';
import { ReactComponent as TelegramIcon } from './../assets/icons/telegram.svg';
import { ReactComponent as InstagramIcon } from './../assets/icons/instagram.svg';
import { ReactComponent as XIcon } from './../assets/icons/x.svg';
import useScrollTo from "../hooks/useScrollTo";

const menuItems = [
    { id: 'about', label: 'О нас', yDesktop: 478, yMobile: 1060 },
    { id: 'how-to-buy', label: 'Как купить?', yDesktop: 1120, yMobile: 2025 },
    { id: 'tokenomics', label: 'Токеномика', yDesktop: 2000, yMobile: 3360 },
];

const Block1: React.FC = () => {
    const [activeItem, setActiveItem] = useState(menuItems[0].id);
    const scrollTo = useScrollTo();

    const handleClick = (id: string, yDesktop: number, yMobile: number) => {
        setActiveItem(id);
        scrollTo(yDesktop, yMobile);
    };

    return (
        <div className="block1">
            <div className="main_info">
                <div className="text_part" style={{width: '343px'}}>
                    <h1 className="title">$ANGEL</h1>
                    <p className="description">Уникальный крипто-проект, созданный для поддержки и объединения людей по всему миру.</p>
                </div>
                <Button3
                    text="Как купить $ANGEL?"
                    href="https://telegra.ph/KAK-KUPIT-TOKEN-ANGEL-09-13"
                />
                <div className="social-buttons">
                    <a target="_blank" href="https://t.me/angel_token1" className="social-button"><TelegramIcon /></a>
                    <a target="_blank" href="https://www.instagram.com/angelina_crypto1?igsh=ejB0M21jNTJuaWxj&utm_source=qr" className="social-button"><InstagramIcon /></a>
                    <a target="_blank" href="https://x.com/AngelMemeToken?t=KCldovP99NW7F4WZzjToEQ&s=09" className="social-button"><XIcon /></a>
                </div>
            </div>
            <img src="/images/image1.png" alt="Project Image" className="project-image" />
        </div>
    );
};

export default Block1;