import React, { useState } from 'react';
import NavButton from './buttons/NavButton';
import CTAButton from './buttons/CTAButton';
import '../styles/MenuPanel.css';
import useScrollTo from '../hooks/useScrollTo';

const menuItems = [
    { id: 'about', label: 'О нас', yDesktop: 478, yMobile: 1060 },
    { id: 'how-to-buy', label: 'Как купить?', yDesktop: 1120, yMobile: 2025 },
    { id: 'tokenomics', label: 'Токеномика', yDesktop: 2000, yMobile: 3360 },
];

const MenuPanel: React.FC = () => {
    const [activeItem, setActiveItem] = useState(menuItems[0].id);
    const scrollTo = useScrollTo();

    const handleClick = (id: string, yDesktop: number, yMobile: number) => {
        setActiveItem(id);
        scrollTo(yDesktop, yMobile);
    };

    const handleCTAClick = () => {
        window.open('https://sunpump.meme/token/TYBrXiqjonSDfhLaqaWvxBVj2zGYvTX96E', '_blank');
    };

    return (
        <div className="menu-panel">
            {menuItems.map((item) => (
                <NavButton
                    key={item.id}
                    isActive={activeItem === item.id}
                    onClick={() => handleClick(item.id, item.yDesktop, item.yMobile)}
                >
                    {item.label}
                </NavButton>
            ))}
            <CTAButton onClick={handleCTAClick} />
        </div>
    );
};

export default MenuPanel;