import React from 'react';
import './styles/App.css';
import MenuPanel from './components/MenuPanel';
import Block1 from './components/Block1';
import Block2 from './components/Block2';
import Block3 from './components/Block3';
import Block4 from './components/Block4';
import Tape from './components/Tape';

const App: React.FC = () => {
    return (
        <div className="app">
            <div className="blur-circle top-left"></div>
            <div className="blur-circle bottom-right"></div>
            <MenuPanel />
            <Block1/>
            <Tape direction="right" />
            <Block2/>
            <Tape direction="left" />
            <h1 className="title2">КАК КУПИТЬ $ANGEL?</h1>
            <Block3/>
            <Tape direction="right" />
            <h1 className="title2">ТОКЕНОМИКА</h1>
            <Block4/>
            <p style={{fontSize: '12px', fontWeight: '400', marginTop: '32px', color: '#D3D1D6', fontFamily: 'Unbounded'}}>@AngelMemeToken (c) 2024 AngelInvest. All rights reserved.</p>
        </div>
    );
};

export default App;