import React from 'react';
import Button3 from './buttons/Button3';

const containerStyle = {
    display: 'flex',
    width: '100%',
    maxWidth: 744,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap' as const,
    gap: '20px 24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: '1',
};

const statStyle = {
    width: 360,
    padding: '16px 24px',
    background: 'rgba(0, 0, 0, 0.50)',
    borderRadius: 24,
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    display: 'inline-flex' as const,
    boxSizing: 'border-box' as const,
};

const headerStyle = {
    alignSelf: 'stretch',
    color: '#C1C1C1',
    fontSize: 20,
    fontFamily: 'Unbounded',
    fontWeight: 400,
    wordWrap: 'break-word' as const,
};

const descriptionStyle = {
    alignSelf: 'stretch',
    color: 'white',
    fontSize: 24,
    fontFamily: 'Unbounded',
    fontWeight: 700,
    wordWrap: 'break-word' as const,
};

const walletButtonStyle = {
    width: '100%',
    minWidth: '360px',
    flex: '1 1 0' as const,
    paddingLeft: 12,
    paddingRight: 12,
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex' as const,
};

const buttonStyle = {
    alignSelf: 'stretch',
    padding: '16px 24px',
    background: '#A154FE',
    borderRadius: 24,
    border: '2px white solid',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    display: 'flex' as const,
};

const linkTextStyle = {
    alignSelf: 'stretch',
    color: 'white',
    fontSize: 20,
    fontFamily: 'Unbounded',
    fontWeight: 400,
    wordWrap: 'break-word' as const,
};

const Block4: React.FC = () => {

    return (
        <div className="TokenomicsStats" style={containerStyle}>
            <div className="Stat" style={statStyle}>
                <div className="StatHeader" style={headerStyle}>Название токена:</div>
                <div className="StatDescription" style={descriptionStyle}>$ANGEL</div>
            </div>
            <div className="Stat" style={statStyle}>
                <div className="StatHeader" style={headerStyle}>Общ. денежная масса:</div>
                <div className="StatDescription" style={descriptionStyle}>1 000 000 000 $ANGEL</div>
            </div>
            <div className="Stat" style={statStyle}>
                <div className="StatHeader" style={headerStyle}>Рыночная капитализация:</div>
                <div className="StatDescription" style={descriptionStyle}>293 000 000$</div>
            </div>
            <div className="Stat" style={statStyle}>
                <div className="StatHeader" style={headerStyle}>Холдеров</div>
                <div className="StatDescription" style={descriptionStyle}>1 561 444</div>
            </div>
            <div className="WalletButton" style={walletButtonStyle}>
                <Button3 text="CA: TSig7sWzEL2K83mkJMQtbyPpiVSbR6pZnb" href="https://sunpump.meme/token/TYBrXiqjonSDfhLaqaWvxBVj2zGYvTX96E" />
            </div>
        </div>
    );
};

export default Block4;