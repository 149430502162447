import { useCallback } from 'react';

const useScrollTo = () => {
    return useCallback((yDesktop: number, yMobile: number) => {
        const isMobile = window.innerWidth <= 768; // Пример определения мобильного устройства
        const yCoordinate = isMobile ? yMobile : yDesktop;

        window.scrollTo({
            top: yCoordinate,
            behavior: 'smooth'
        });
    }, []);
};

export default useScrollTo;