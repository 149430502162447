import React from 'react';
import '../../styles/NavButton.css';

interface NavButtonProps {
    children: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({ children, isActive, onClick }) => {
    return (
        <button
            className={`nav-button ${isActive ? 'active' : ''}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default NavButton;